import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../Styles/Routes/Categories.css";
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import { Helmet } from "react-helmet";
import { COLORS } from "../../Utils/COLORS";
import NoProduct from "../../Components/CMS/Inventory/NoProduct";
import axios from "axios";
import { Route_Endpoints } from "../../Utils/Constants";
import { environment } from "../../API";

export default function Categories() {
  const navigate = useNavigate();
  const [Categories, setCategories] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const handleCategoryUpdate = (index) => {
    const category = Categories.find(
      (category) => category.CategoryId === index
    );
    setSelectedCategory(category);
    navigate("/categories/update-category", { state: { category } });
  };

  const handleCategoryDelete = async (categoryId) => {
    try {
      await axios.post(`${environment.baseURL}/admin/deleteCategory`, {
        CategoryId: categoryId,
      });
      fetchCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${environment.baseURL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const navigateToCategoryProducts = (categoryName) => {
    navigate(Route_Endpoints.CATEGORY_ROUTES.CATEGORY_PRODUCTS, {
      state: { categoryName },
    });
  };

  return (
    <>
      <Helmet>
        <title>Categories | Dori Decor</title>
      </Helmet>
      <div style={{ width: "100%" }}>
        <div className="m-2">
          <HeaderInput />
        </div>
        <div
          onClick={() => {
            navigate("/categories/add-category");
          }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: COLORS.primary,
              width: "fit-content",
              padding: "10px 20px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <span>Add New Category</span>
          </div>
        </div>
        <div className="inventory-container row container-fluid">
          {Categories.length > 0
            ? Categories.map((category, index) => (
                <div
                  className="col-md-6"
                  key={category.CategoryId}
                  onClick={() =>
                    navigateToCategoryProducts(category.CategoryName)
                  }
                >
                  <div className="inventory-product">
                    <div>
                      <div className="inventory-product-image">
                        <img
                          src={category.CategoryImage}
                          alt={category.CategoryName}
                        />
                      </div>
                    </div>
                    <div className="inventory-product-info">
                      <div>
                        <p>
                          <span>
                            <b>{category.CategoryName}</b>
                          </span>
                        </p>
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCategoryUpdate(category.CategoryId);
                        }}
                        style={{ backgroundColor: COLORS.primary }}
                        className="inventory-action-btn"
                      >
                        Update Category
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCategoryDelete(category.CategoryId);
                        }}
                        style={{ backgroundColor: COLORS.danger }}
                        className="inventory-action-btn"
                      >
                        Delete Category
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : !Loading && <NoProduct />}
        </div>
      </div>
    </>
  );
}
