import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetAllWarehouse } from "../../../API/Store/Warehouse";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Helmet } from "react-helmet";
import "../../../Styles/Routes/EditProduct.css";
import HeaderInput from "../../../Components/CMS/Dashboard/HeaderInput";
import { GetProductById, UpdateProduct } from "../../../API/Products/Products";
import { storage } from "../../../Utils/firebase";
import { toast } from "react-hot-toast";
import { CloudUploadOutlined } from "@material-ui/icons";
import UnstyledInputBasic from "../../../Components/Elements/UnstyledInput";
import PrimarySelect from "../../../Components/Elements/PrimarySelect";
import { Route_Endpoints } from "../../../Utils/Constants";
import { COLORS } from "../../../Utils/COLORS";
import { Paper } from "@mui/material";
import InputAdornments from "../../../Components/Elements/CustomInput";

import UnstyledSnackbarIntroduction from "../../../Components/Elements/UnstyledSnackBar";
import axios from "axios";
import { environment } from "../../../API";
import { Reorder } from "framer-motion";
import { awsSdk } from "../../../Utils/awsSdk.config";

export default function EditProduct() {
  const { ProductId } = useLocation().state;

  const [ProductImages, setProductImages] = useState([]);

  const [ProductDescription, setProductDescription] = useState("");
  const [ExistingImages, setExistingImages] = useState([]);
  const [ProductName, setProductName] = useState("");
  const [ProductCategory, setProductCategory] = useState("");
  const [SelectedWarehouse, setSelectedWarehouse] = useState();
  const [Warehouse, setWarehouse] = useState([]);
  const [OrderProcessingTime, setOrderProcessingTime] = useState("");
  const [skuValue, setSkuValue] = useState("");
  const [Loading, setLoading] = useState(false);
  const [ProductWeight, setProductWeight] = useState();
  const [Variants, setVariants] = useState([]);
  const [Categories, setCategories] = React.useState([]);

  var arr = [];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${environment.baseURL}/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const uploadImageToCloud = async () => {
    const s3 = new awsSdk.S3();
    const bucketName = "dorihandcrafts";
    const folderPath = "images";

    try {
      arr = [];

      for (const image of ProductImages) {
        const dateTimeForImage = new Date().getTime();
        // eslint-disable-next-line no-loop-func
        await new Promise(async (resolve, reject) => {
          try {
            const params = {
              Bucket: bucketName,
              Key: `${folderPath}/${dateTimeForImage}-${image.name}`,
              Body: image,
              ACL: "public-read",
              ContentType: image.type,
            };
            const data = await s3.upload(params).promise();
            const location = data.Location;
            console.log(location);
            arr.push({ id: Math.random(), img: location });
            resolve();
          } catch (err) {
            reject(err);
          }
        });
      }

      return { done: true, err: null }; // All images were uploaded successfully
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const addVariant = async () => {
    const variantName = document.getElementById("variantName").value;
    const variantPrice = document.getElementById("variantPrice").value;
    const variantMRP = document.getElementById("variantMRP").value;
    const variantQuantity = document.getElementById("variantQuantity").value;

    const newVariant = {
      Name: variantName,
      Description: "Perfect for small pots and spaces.",
      Price: parseFloat(variantPrice),
      MRP: parseFloat(variantMRP),
      Customizable: true,
      CustomizationOptions: ["Color", "Size"],
      Quantity: parseInt(variantQuantity),
      VariantImages: JSON.stringify(
        ProductImages.map((image, index) => ({
          img: URL.createObjectURL(image),
          id: Math.random(),
          position: index + 1,
        }))
      ),
    };

    setVariants([...Variants, newVariant]);
  };

  const addProductImage = (image) => {
    setProductImages([...ProductImages, image]);
  };

  const handleImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addProductImage(image));
  };
  const Update = async () => {
    console.log(ProductWeight);
    setLoading(true);
    const { done, err } = await uploadImageToCloud();
    if (done) {
      console.log("Image Uploaded Successfully");

      const s3 = new awsSdk.S3();
      const bucketName = "dorihandcrafts";
      const folderPath = "images";

      for (const variant of Variants) {
        for (const image of variant.VariantImages) {
          // Convert Blob URL to Blob object
          const blob = await fetch(image.img).then((res) => res.blob());

          const params = {
            Bucket: bucketName,
            Key: `${folderPath}/${image.id}`,
            Body: blob,
            ACL: "public-read",
            ContentType: blob.type,
          };
          try {
            const data = await s3.upload(params).promise();
            const location = data.Location;
            console.log(location);
            image.img = location;
          } catch (error) {
            console.error("Error uploading image:", error);
            // Handle error as needed
          }
        }
      }
      alert(ProductCategory);

      UpdateProduct({
        ProductId,
        ProductName,
        Description: ProductDescription,
        Category: ProductCategory,
        variants: Variants,
        ProductImage: arr,
        WarehouseId: SelectedWarehouse,
        CategoryId: "1212",
        OrderProcessingTime: OrderProcessingTime,
        Weight: ProductWeight,
        SKU: skuValue,
      })
        .then((res) => {
          console.log(res);
          setLoading(false);
          setTimeout(() => {
            window.location.replace("https://admin.doridecor.in/inventory/");
          }, 1000);
        })
        .catch((err) => {
          const errMsg = err.response.data.message;
          toast.error(errMsg);
          setLoading(false);
        });
    }
    if (!done || err) {
      toast.error("Error Occured While Uploading Images");
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    GetProductById({ ProductId })
      .then((ProductData) => {
        console.log(ProductData);
        const ProductInfo = ProductData.product;
        setProductName(ProductInfo.Name);
        setProductDescription(ProductInfo.Description);
        setVariants(ProductInfo.variants);
        setSelectedWarehouse(ProductInfo.WarehouseId);
        setOrderProcessingTime(ProductInfo.OrderProcessingTime);
        setSkuValue(ProductInfo.SKU);
        setProductCategory(ProductInfo.Category);

        setProductWeight(ProductInfo.variants[0].Weight);
        setExistingImages(JSON.parse(ProductInfo.ProductImage));

        // setProductImages(JSON.parse(ProductInfo.ProductImage));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ProductId]);

  return (
    <div className="edit-product-container">
      <Helmet>
        <title>Edit Product | Dori Decor</title>
      </Helmet>

      <div className="container-fluid my-3">
        <div className="m-2">
          <HeaderInput />
        </div>
        <div className="row">
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleImageSelect(e);
              }}
              id="image-input"
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {Variants.map((variant) => {
                return (
                  <div
                    onClick={() => {
                      setVariants(
                        Variants.filter((v) => v.Name !== variant.Name)
                      );
                    }}
                    className="m-1 Add-Product-Variant-Box"
                  >
                    <Paper elevation={3}>
                      <div className="p-3">
                        <div className="fw-bold">{variant.Name}</div>
                        <div
                          style={{
                            fontSize: "12px",
                          }}
                          className=" my-2"
                        >
                          <span>
                            MRP: <span className="fw-bold">₹{variant.MRP}</span>
                          </span>{" "}
                          |{" "}
                          <span>
                            Price:{" "}
                            <span className="fw-bold">₹{variant.Price}</span>
                          </span>{" "}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <span>
                            Quantity:{" "}
                            <span className="fw-bold">{variant.Quantity}</span>
                          </span>{" "}
                        </div>
                        {/* Display variant images */}
                        <div className="variant-images">
                          {variant?.VariantImages
                            ? JSON.parse(variant.VariantImages).map(
                                (image, index) => (
                                  <img
                                    key={image.id}
                                    src={image.img}
                                    alt={`Variant Image ${index + 1}`}
                                    className="variant-image"
                                  />
                                )
                              )
                            : null}
                        </div>
                      </div>
                    </Paper>
                  </div>
                );
              })}
            </div>
            <div className="my-3 add-product-card">
              <div className="add-product-heading mx-1 mb-4">
                Product Variants
              </div>
              <div className="my-3 add-product-image-upload">
                <div
                  onClick={() => {
                    document.getElementById("image-input").click();
                  }}
                >
                  <span className="add-product-image-upload-icon">
                    <CloudUploadOutlined
                      style={{
                        fontSize: "50px",
                      }}
                    />
                  </span>
                </div>
                <div className="fw-bold text-center add-product-image-upload-msg my-2">
                  <span>
                    Give your product a visual appeal - upload an image.
                    Recommended size: 900x600 pixels."
                  </span>
                </div>
              </div>
              <Reorder.Group
                axis="x"
                onReorder={setProductImages}
                values={ProductImages}
                className="add-product-image-list"
              >
                {ProductImages.length > 0 &&
                  ProductImages.map((image, index) => (
                    <Reorder.Item
                      value={image}
                      key={image.name}
                      initial={{ opacity: 0, y: 30 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.15 },
                      }}
                      exit={{
                        opacity: 0,
                        y: 20,
                        transition: { duration: 0.3 },
                      }}
                      drag
                      className="add-product-image-item"
                    >
                      <div
                        key={image.name}
                        onClick={() => {
                          setProductImages(
                            ProductImages.filter(
                              (img) => img.name !== image.name
                            )
                          );
                        }}
                      >
                        <img
                          className="add-product-image"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                      </div>
                    </Reorder.Item>
                  ))}
              </Reorder.Group>
              <div>
                <div className="my-3">
                  <UnstyledInputBasic
                    id="variantName"
                    className="add-product-info-input"
                    placeholder={"Variant Name (eg. Color or Size) "}
                  />
                </div>

                <div className="row">
                  <div className="col-md-6 my-2">
                    <UnstyledInputBasic
                      id="variantMRP"
                      className="add-product-info-input "
                      type={"number"}
                      placeholder={"Variant Price"}
                    />
                  </div>
                  <div className="col-md-6 my-2">
                    <UnstyledInputBasic
                      id="variantPrice"
                      type={"number"}
                      className="add-product-info-input "
                      placeholder={" Variant Selling Price"}
                    />
                  </div>
                </div>

                <div className="my-3">
                  <UnstyledInputBasic
                    type={"number"}
                    id="variantQuantity"
                    className="add-product-info-input "
                    placeholder={"Quantity of Variant Available in Stock"}
                  />
                </div>
                <div
                  onClick={addVariant}
                  style={{
                    backgroundColor: COLORS.primary,
                  }}
                  className="store-update-button"
                >
                  Add Variant
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleImageSelect(e);
              }}
              id="image-input"
            />
            <div className="my-3 edit-product-card">
              <div className="edit-product-heading mx-1 mb-4">Product Info</div>
              <div>
                <div className="my-3">
                  <UnstyledInputBasic
                    value={ProductName}
                    className="edit-product-info-input"
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                    placeholder={"Product Name"}
                  />
                </div>
                <div className="my-3">
                  <select
                    value={ProductCategory}
                    name="Product Category"
                    className="edit-product-info-select"
                    onChange={(e) => {
                      setProductCategory(e.target.value);
                    }}
                  >
                    {Categories.map((category) => (
                      <option
                        key={category.CategoryId}
                        value={category.CategoryName}
                      >
                        {category.CategoryName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="my-3">
                  <UnstyledInputBasic
                    value={skuValue}
                    className="edit-product-info-input"
                    onChange={(e) => {
                      //regex to allow only numbers
                      if (e.target.value.match(/^[0-9]*$/)) {
                        setSkuValue(e.target.value);
                      }
                    }}
                    placeholder={"SKU"}
                  />
                </div>
                <div className="my-3">
                  <InputAdornments
                    value={ProductWeight}
                    onChange={(e) => {
                      //regex to allow only numbers
                      if (e.target.value.match(/^[0-9]*$/)) {
                        setProductWeight(e.target.value);
                      }
                    }}
                    placeholder={"Weight of the Product"}
                    type={"number"}
                  />
                </div>
                <div className="my-3">
                  <UnstyledInputBasic
                    value={OrderProcessingTime}
                    className="edit-product-info-input"
                    onChange={(e) => {
                      //regex to allow only numbers
                      if (e.target.value.match(/^[0-9]*$/)) {
                        setOrderProcessingTime(e.target.value);
                      }
                    }}
                    placeholder={"Order Processing Time (Days)"}
                  />
                </div>
                <div className="my-3">
                  <ReactQuill
                    theme="snow"
                    value={ProductDescription}
                    onChange={setProductDescription}
                    placeholder={"Product Description"}
                    style={{
                      height: "400px",
                      paddingBottom: "30px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {}
        <div
          onClick={Loading ? null : Update}
          className="m-4 store-update-button"
          style={{
            backgroundColor: COLORS.primary,
          }}
        >
          {Loading ? (
            <>
              <div>
                <div class="spinner-border text-dark " role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            "Update Product Details"
          )}
        </div>
      </div>
    </div>
  );
}
