import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderInput from "../../../Components/CMS/Dashboard/HeaderInput";
import UnstyledInputBasic from "../../../Components/Elements/UnstyledInput";
import { COLORS } from "../../../Utils/COLORS";
import { CloudUploadOutlined } from "@material-ui/icons";
import { storage } from "../../../Utils/firebase";
import { toast } from "react-hot-toast";
import axios from "axios";
import { environment } from "../../../API";
import { awsSdk } from "../../../Utils/awsSdk.config";

const AddBanner = () => {
  const [BannerImage, setBannerImage] = useState([]);
  const [BannerHeading, setBannerHeading] = useState("");
  const [BannerDetails, setBannerDetails] = useState("");
  const [BannerLink, setBannerLink] = useState("");
  const [Loading, setLoading] = useState(false);
  var arr = [];

  const handleImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addProductImage(image));
  };

  const uploadImageToCloud = async () => {
    try {
      arr = []; // Assuming this is declared somewhere in your code

      for (const image of BannerImage) {
       
      
        // eslint-disable-next-line no-loop-func
        await new Promise(async(resolve, reject) => {
        try{
          const s3 = new awsSdk.S3();
          const bucketName = "dorihandcrafts";
          const folderPath = "banners";
          const dateTimeForImage = new Date().getTime();
          const params = {
            Bucket: bucketName,
            Key: `${folderPath}/${dateTimeForImage}-${image.name}`,
            Body: image,
            ACL: "public-read",
            ContentType: image.type,
          };
          const data = await s3.upload(params).promise();
          const location = data.Location;
          console.log(location);
          arr.push({ id: Math.random(), img: location });
          resolve();
        }
        catch(err){
          console.log(err);
          reject();
        }
      
        });
      }

      return { done: true, err: null }; // All images were uploaded successfully
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const addProductImage = (image) => {
    setBannerImage([...BannerImage, image]);
  };

  const addBanner = async () => {
    setLoading(true);
    const { done, err } = await uploadImageToCloud();
    if (done) {
      console.log("Image Uploaded Successfully");
      const authToken = localStorage.getItem("AuthToken");
      const bannerData = {
        Image: arr[0].img, // Use the first image URL
        Heading: BannerHeading,
        Link: BannerLink, // Replace with the actual link value
        detailText: BannerDetails, // Replace with the actual detail text value
      };
      axios
        .post(`${environment.baseURL}/admin/createBanner`, bannerData, {
          headers: {
            AuthToken: authToken,
          },
        })
        .then((res) => {
          console.log(res);
          toast.success("Banner Added Successfully 📂");
          setLoading(false);
          window.location.reload();
          //hard refresh
          
          // Optionally, you can reset the form or navigate to another page
        })
        .catch((err) => {
          const errMsg = err.response.data.message;
          toast.error(errMsg);
          setLoading(false);
        });
    }
    if (!done) {
      toast.error("Error Occurred While Uploading Image");
      setLoading(false);
    }
    if (err) {
      toast.error("Error Occurred While Uploading Image");
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Add Banner | Dori Decore</title>
      </Helmet>
      <div style={{ width: "170vw" }}>
        <div className="m-2">
          <HeaderInput />
        </div>
        <div className="col-md-6">
          <input
            type="file"
            hidden
            onChange={(e) => {
              handleImageSelect(e);
            }}
            id="image-input"
          />
          <div className="my-3 add-product-card">
            <div className="add-product-heading">Add Banner</div>
            <div className="my-3 add-product-image-upload">
            
            
           {!BannerImage?.length > 0 &&
            <div
            onClick={() => {
              document.getElementById("image-input").click();
            }}
          >
            <span className="add-product-image-upload-icon">
              <CloudUploadOutlined style={{ fontSize: "50px" }} />
            </span>
          </div>
            }
             
              <div className="fw-bold text-center add-product-image-upload-msg my-2">
                <span>
                  Give your Banner a visual appeal - upload an image.
                  Recommended size: 900x600 pixels."
                </span>
              </div>
            </div>
            <div className="add-product-image-list" style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}>
              {BannerImage?.length > 0 &&
                BannerImage?.map((image) => (
                  <div
                    key={image.name}
                    onClick={() => {
                      setBannerImage(
                        BannerImage.filter((img) => img.name !== image.name)
                      );
                    }}
                    className="add-product-image-item"
                  >
                    <img
                      className="add-product-image"
                      src={URL.createObjectURL(image)}
                      alt=""
                    />
                  </div>
                ))}
            </div>
            <div>
              <div className="my-3">
                <UnstyledInputBasic
                  id="heading"
                  className="add-product-info-input"
                  placeholder={"Banner Heading"}
                  value={BannerHeading}
                  onChange={(e) => setBannerHeading(e.target.value)}
                />
              </div>
              <div className="my-3">
                <UnstyledInputBasic
                  id="heading"
                  className="add-product-info-input"
                  placeholder={"Banner Deatils"}
                  value={BannerDetails}
                  onChange={(e) => setBannerDetails(e.target.value)}
                />
              </div>
              <div className="my-3">
                <UnstyledInputBasic
                  id="heading"
                  className="add-product-info-input"
                  placeholder={"Banner Button Link"}
                  value={BannerLink}
                  onChange={(e) => setBannerLink(e.target.value)}
                />
              </div>
              <div
                onClick={addBanner}
                style={{ backgroundColor: COLORS.primary }}
                className="store-update-button py-3"
              >
                {Loading ? (
                  <>
                    <div>
                      <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  "Add Banner"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
