import AWS from 'aws-sdk';

const awsReactCred = {
    accessKey: 'AKIAXK5XZ46F6PYP4DGU',
    accessKeySecret: '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',
    region: 'ap-south-1'
}

// Set the global region for all AWS service requests
AWS.config.update({
    accessKeyId: awsReactCred.accessKey,
    secretAccessKey: awsReactCred.accessKeySecret,
    region: awsReactCred.region,

})

export const awsSdk = AWS;