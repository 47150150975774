import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import SalesStat from "../../Components/Analytics/Graphs/Sales/SalesStat";
import InfoBox from "../../Components/CMS/Dashboard/InfoBox";
import "../../Styles/Routes/DashBoard.css";
import RecentOrderTable from "../../Components/CMS/Dashboard/RecentOrderTable";
import SalesByCategory from "../../Components/Analytics/Graphs/Sales/SalesByCategory";
import SemiCricularGuage from "../../.Dev/SemiCircularGuage";
import TopSellingTable from "../../Components/CMS/Dashboard/TopSellingTable";
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import { getAnalytics } from "../../API/Analytics/Sales";
export default function DashBoard() {
  const [SalesMatrics, setSalesMatrics] = React.useState([
]);
  useEffect(() => {
   getAnalytics().then((res) => {
  
    setSalesMatrics(res.salesMetric)
    console.log(res)
   }).catch((err) => {
    alert(err)
   }
  )
  }, []);
  return (
    <div className="container-fluid ">
      <Helmet>
        <title>Dashboard | Rebuilters</title>
      </Helmet>
      <div className="m-2">
        <HeaderInput />
      </div>
      <div className="my-3 GradientBoxContainers">
        {SalesMatrics.map((item) => {
          return (
            <InfoBox
              Heading={item.label}
              DataText={item.value}
              GradientColors={["#FABB00", "#C48FFF80"]}
            />
          );
        })}
      </div>

      <div>
        <SalesStat />
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            width: "65%",
          }}
        >
          <RecentOrderTable />
        </div>
        <div
          style={{
            width: "35%",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gridTemplateRows: "1fr 1fr",
              height: "100%",
            }}
            className="mb-5 mx-2"
          >
            
          </div>
        </div>
      </div>
      <div>
        <SalesByCategory />
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            width: "25%",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gridTemplateRows: "1fr 1fr",
              height: "100%",
            }}
            className="mb-5 mx-2"
          >
            <div
              style={{
                border: "1px solid #e5e5e5 ",
                borderRadius: "10px",
              }}
              className="mb-3 p-2"
            >
              <span
                style={{
                  fontSize: "12px",
                  color: "#9E9E9E",
                  fontWeight: "bold",
                }}
              >
                Statistics
              </span>
              <br />
              <span
                className="sales-stat-title"
                style={{
                  padding: "10px",
                }}
              >
                Transactions
              </span>
            </div>
            <div
              style={{
                border: "1px solid #e5e5e5 ",
                borderRadius: "10px",
              }}
              className="mb-3 p-2"
            >
              <span
                style={{
                  fontSize: "12px",
                  color: "#9E9E9E",
                  fontWeight: "bold",
                }}
              >
                Statistics
              </span>
              <br />
              <span
                className="sales-stat-title"
                style={{
                  padding: "10px",
                }}
              >
                Total Visits
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "75%",
          }}
        >
          <TopSellingTable />
        </div>
      </div>
    </div>
  );
}
