import React, { useEffect } from "react";
import { DeleteProduct, GetAllProducts } from "../../API/Products/Products";
import { Link, useNavigate, useNavigation } from "react-router-dom";

import "../../Styles/Routes/Inventory.css"; // Import the CSS file
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import { Helmet } from "react-helmet";
import { COLORS } from "../../Utils/COLORS";
import { Route_Endpoints } from "../../Utils/Constants";
import NoProduct from "../../Components/CMS/Inventory/NoProduct";

export default function Inventory() {
  const navigate = useNavigate();

  const [Products, setProducts] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  useEffect(() => {
    GetAllProducts()
      .then((res) => {
        console.log(res);
        setProducts(res.products);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Inventory | Dori Decor</title>
      </Helmet>
      <div
        style={{
          width: "100%",
        }}
      >
        <div className="m-2">
          <HeaderInput />
        </div>

        <div
          onClick={() => {
            navigate("/inventory/AddProduct");
          }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: COLORS.primary,
              width: "fit-content",
              padding: "10px 20px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <span>Add New Product</span>
          </div>
        </div>

        <div className="inventory-container row container-fluid">
          {Products.length > 0
            ? Products?.map((product, index) => (
                <div className="col-md-6">
                  <div key={index} className="inventory-product">
                    <div>
                      <div className="inventory-product-image">
                        <img
                          src={JSON.parse(product.ProductImage)[0]?.img}
                          alt={product.Name}
                        />
                      </div>
                    </div>
                    <div className="inventory-product-info">
                      <div>
                        <p>
                          <span>
                            <b>{product.Name}</b>
                          </span>
                        </p>
                      </div>
                      <div>
                        <span>
                          <b>Price</b>: {product.price}
                        </span>
                      </div>
                      <div>
                        <span>
                          <b>MRP</b>: {product.mrp}
                        </span>
                      </div>
                      <div></div>
                    </div>
                    <div className="inventory-product-actions">
                      <div
                        onClick={() => {
                          navigate(
                            Route_Endpoints.INVENTORY_ROUTES.EDIT_PRODUCT,
                            {
                              state: {
                                ProductId: product.ProductId,
                              },
                            }
                          );
                        }}
                        className="inventory-action-btn bg-success fw-bold text-white"
                      >
                        <span>Edit Product</span>
                      </div>
                      <div
                        onClick={() => {
 const askPrompt = window.confirm("Are you sure you want to delete this product?" );
if(!askPrompt) return;
                          DeleteProduct({ ProductId: product.ProductId }).then(
                            (res) => {
                              console.log(res);
                             
                                alert("Product Deleted Successfully");
                                window.location.reload();
                             
                            }
                          );
                        }}
                        className="inventory-action-btn bg-danger fw-bold text-white"
                      >
                        <span>Delete Product</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : !Loading && <NoProduct />}
        </div>
      </div>
    </>
  );
}
