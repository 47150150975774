import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { Helmet } from "react-helmet";
import "../../Styles/Routes/OrderDetails.css";
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import { GetProductById, UpdateProduct } from "../../API/Products/Products";
import { storage } from "../../Utils/firebase";
import { toast } from "react-hot-toast";
import UnstyledInputBasic from "../../Components/Elements/UnstyledInput";
import { COLORS } from "../../Utils/COLORS";
import { MenuItem, Paper, Select } from "@mui/material";
import InputAdornments from "../../Components/Elements/CustomInput";

const SHIPPING_STATUS = [
  {
    status: "pending",
  },
  {
    status: "In Process",
  },
  {
    status: "Dispatch",
  },
  {
    status: "Out for Delivery",
  },
  {
    status: "Delivered",
  },
];

const SHIPPING_DETAILS = [
  {
    title: "Order ID:",
    value: "#1234",
  },
  {
    title: "Name:",
    value: "Joy Medrik",
  },
  {
    title: "Address:",
    value: "A-401, bentown",
  },
  {
    title: "Landmark:",
    value: "sector-2 east",
  },
  {
    title: "City:",
    value: "Elephant Butte",
  },
  {
    title: "State:",
    value: "New York",
  },
  {
    title: "Pincode:",
    value: "74834",
  },
  {
    title: "Phone Number:",
    value: "928367269",
  },
];

export default function ReturnExchangeDetail() {
  const { ProductId } = useLocation().state;

  const [ProductImages, setProductImages] = useState([]);

  const [ProductDescription, setProductDescription] = useState("");
  const [ExistingImages, setExistingImages] = useState([]);
  const [ProductName, setProductName] = useState("");
  const [ProductCategory, setProductCategory] = useState("");
  const [SelectedWarehouse, setSelectedWarehouse] = useState();
  const [OrderProcessingTime, setOrderProcessingTime] = useState();
  const [Loading, setLoading] = useState(false);
  const [ProductWeight, setProductWeight] = useState();
  const [Variants, setVariants] = useState([]);
  const [ShippingStatus, setShippingStatus] = useState(
    SHIPPING_STATUS[0].status
  );
  var arr = [];

  const handleStatusChange = (event) => {
    setShippingStatus(event.target.value);
  };

  const uploadImageToCloud = async () => {
    try {
      arr = []; // Assuming this is declared somewhere in your code

      for (const image of ProductImages) {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null, // Progress function is not needed in this case
            (error) => {
              // Error function ...
              console.log(error);
              reject(error); // Reject the promise if an error occurs during upload
            },
            () => {
              // complete function ...
              storage
                .ref("images")
                .child(image.name)
                .getDownloadURL()
                .then((url) => {
                  arr.push({ id: Math.random(), img: url });

                  resolve(); // Resolve the promise once the URL is obtained
                })
                .catch((error) => {
                  console.log(error);
                  reject(error); // Reject the promise if an error occurs while getting the download URL
                });
            }
          );
        });
      }

      return { done: true, err: null }; // All images were uploaded successfully
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const addVariant = () => {
    setVariants([
      ...Variants,
      {
        Name: document.getElementById("variantName").value,
        Price: document.getElementById("variantPrice").value,
        MRP: document.getElementById("variantMRP").value,
        Quantity: document.getElementById("variantQuantity").value,
      },
    ]);
  };

  const addProductImage = (image) => {
    setProductImages([...ProductImages, image]);
  };

  const handleImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addProductImage(image));
  };
  const Update = async () => {
    console.log(ProductWeight);
    setLoading(true);
    const { done, err } = await uploadImageToCloud();
    if (done) {
      ExistingImages.forEach((image) => {
        arr.push(image);
      });
      UpdateProduct({
        ProductId,
        ProductName,
        Description: ProductDescription,
        Category: ProductCategory,
        variants: Variants,
        ProductImage: arr,
        WarehouseId: SelectedWarehouse,
        CategoryId: "1212",
        OrderProcessingTime: OrderProcessingTime,
        Weight: ProductWeight,
      })
        .then((res) => {
          console.log(res);
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          const errMsg = err.response.data.message;
          toast.error(errMsg);
          setLoading(false);
        });
    }
    if (!done || err) {
      toast.error("Error Occured While Uploading Images");
      setLoading(false);
    }
  };

  useEffect(() => {
    GetProductById({ ProductId })
      .then((ProductData) => {
        console.log(ProductData);
        const ProductInfo = ProductData.product;
        setProductName(ProductInfo.Name);
        setProductDescription(ProductInfo.Description);
        setVariants(ProductInfo.variants);
        setSelectedWarehouse(ProductInfo.WarehouseId);
        setOrderProcessingTime(ProductInfo.OrderProcessingTime);
        setProductCategory(ProductInfo.Category);

        setProductWeight(ProductInfo.variants[0].Weight);
        setExistingImages(JSON.parse(ProductInfo.ProductImage));

        // setProductImages(JSON.parse(ProductInfo.ProductImage));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ProductId]);

  return (
    <div className="edit-product-container">
      <Helmet>
        <title>Return Exchange Detail | Dori Decor</title>
      </Helmet>

      <div className="container-fluid my-3">
        <div className="m-2">
          <HeaderInput />
        </div>
        <div className="row">
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleImageSelect(e);
              }}
              id="image-input"
            />
            <div className="my-3 edit-product-card">
              <div className="edit-product-heading">Product Images</div>
              <div className="edit-product-image-list">
                {ExistingImages.length > 0 &&
                  ExistingImages.map((image) => (
                    <div key={image.img} className="edit-product-image-item">
                      <img
                        className="edit-product-image"
                        src={image.img}
                        alt=""
                      />
                    </div>
                  ))}
                {ProductImages.length > 0 &&
                  ProductImages.map((image) => (
                    <div key={image.name} className="edit-product-image-item">
                      <img
                        className="edit-product-image"
                        src={URL.createObjectURL(image)}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div></div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {Variants.map((variant) => {
                return (
                  <div className="m-1 edit-product-Variant-Box">
                    <Paper elevation={3}>
                      <div className="p-3">
                        <div className="fw-bold">{variant.Name}</div>
                        <div
                          style={{
                            fontSize: "12px",
                          }}
                          className=" my-2"
                        >
                          <span>
                            MRP: <span className="fw-bold">₹{variant.MRP}</span>
                          </span>{" "}
                          |{" "}
                          <span>
                            Price:{" "}
                            <span className="fw-bold">₹{variant.Price}</span>
                          </span>{" "}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <span>
                            Quantity:{" "}
                            <span className="fw-bold">{variant.Quantity}</span>
                          </span>{" "}
                        </div>
                      </div>
                    </Paper>
                  </div>
                );
              })}
            </div>
            <div className="my-3 edit-product-card">
              <div className="edit-product-heading mx-1 mb-4">
                Shipping Details
              </div>
              <div className="my-3">
                {SHIPPING_DETAILS.map((field) => (
                  <div className="my-3">
                    <UnstyledInputBasic
                      value={field.value}
                      className="edit-product-info-input"
                      onChange={(e) => {
                        setProductName(e.target.value);
                      }}
                      placeholder={field.title}
                    />
                  </div>
                ))}
              </div>
              <div
                onClick={Loading ? null : Update}
                className="order-button"
                style={{
                  backgroundColor: COLORS.primary,
                }}
              >
                {Loading ? (
                  <>
                    <div>
                      <div class="spinner-border text-dark " role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  "Update Shipping Details"
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleImageSelect(e);
              }}
              id="image-input"
            />
            <div className="my-3 edit-product-card">
              <div className="edit-product-heading mx-1 mb-4">Product Info</div>
              <div>
                <div className="my-3">
                  <UnstyledInputBasic
                    value={ProductName}
                    className="edit-product-info-input"
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                    placeholder={"Product Name"}
                  />
                </div>
                <div className="my-3">
                  <UnstyledInputBasic
                    value={ProductCategory}
                    className="edit-product-info-input "
                    onChange={(e) => {
                      setProductCategory(e.target.value);
                    }}
                    placeholder={"Product Category"}
                  />
                </div>
                <div className="my-3">
                  <InputAdornments
                    value={ProductWeight}
                    onChange={(e) => {
                      //regex to allow only numbers
                      if (e.target.value.match(/^[0-9]*$/)) {
                        setProductWeight(e.target.value);
                      }
                    }}
                    placeholder={"Weight of the Product"}
                    type={"number"}
                  />
                </div>
                <div className="my-3">
                  <UnstyledInputBasic
                    value={OrderProcessingTime}
                    className="edit-product-info-input"
                    onChange={(e) => {
                      //regex to allow only numbers
                      if (e.target.value.match(/^[0-9]*$/)) {
                        setOrderProcessingTime(e.target.value);
                      }
                    }}
                    placeholder={"Order Processing Time (Days)"}
                  />
                </div>
              </div>
            </div>
            <div className="my-3 edit-product-card">
              <div className="edit-product-heading mx-1 mb-4">
                Shipping Status
              </div>
              <Select
                className="shipping-status-select"
                value={ShippingStatus}
                onChange={handleStatusChange}
              >
                {SHIPPING_STATUS.map((item) => (
                  <MenuItem key={item.status} value={item.status}>
                    {item.status}
                  </MenuItem>
                ))}
              </Select>

              <div
                onClick={Loading ? null : Update}
                className="order-button"
                style={{
                  backgroundColor: COLORS.primary,
                }}
              >
                {Loading ? (
                  <>
                    <div>
                      <div class="spinner-border text-dark " role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  "Update Status"
                )}
              </div>
            </div>
          </div>
        </div>
        {}
        <div className="order-button-layout">
          <div
            className="order-button"
            style={{
              backgroundColor: COLORS.danger,
            }}
          >
            {Loading ? (
              <>
                <div>
                  <div class="spinner-border text-dark " role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              "Cancel Order"
            )}
          </div>
          <div
            className="order-button"
            style={{
              backgroundColor: COLORS.primary,
            }}
          >
            {Loading ? (
              <>
                <div>
                  <div class="spinner-border text-dark " role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              "Accept Order"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
