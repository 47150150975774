import instance from "..";
import { ProductsEndpoint } from "../endpoints";

export const GetAllProducts = async () => {
  return new Promise((resolve, reject) => {
    instance
      .post(ProductsEndpoint.getAllProducts)
      .then((res) => {
        console.log(res.data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetProductById = async ({ ProductId }) => {
  return new Promise((resolve, reject) => {
    instance
      .post(ProductsEndpoint.getProductDetails, {
        ProductId,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const AddNewProduct = async ({
  ProductName,
  Description,
  Category,
  CategoryId,
  WarehouseId,
  ProductImage,
  variants,
  OrderProcessingTime,
  SKU,
}) => {
  return new Promise((resolve, reject) => {
    instance
      .post(ProductsEndpoint.addNewProduct, {
        Name: ProductName,
        Description,
        Category,
        CategoryId,
        WarehouseId: "12",
        ProductImage,
        variants,
        OrderProcessingTime,
        SKU,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const UpdateProduct = async ({
  ProductId,
  ProductName,
  Description,
  Category,
  CategoryId,
  WarehouseId,
  ProductImage,
  variants,
  OrderProcessingTime,
  Weight,
  SKU,
}) => {
  return new Promise((resolve, reject) => {
    instance
      .post(ProductsEndpoint.updateProduct, {
        ProductId,
        Name: ProductName,
        Description,
        Category,
        CategoryId,
        WarehouseId,
        ProductImage,
        variants,
        OrderProcessingTime,
        Weight,
        SKU,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const DeleteProduct = async ({ ProductId }) => {
  return new Promise((resolve, reject) => {
    instance
      .post(ProductsEndpoint.deleteProduct, {
        ProductId,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}