import React, { useEffect, useState } from "react";
import "../../../Styles/Elements/RecentOrderTable.css";
import { getTotalOrders } from "../../../API/Analytics/Orders";

export default function RecentOrderTable() {
  const [Orders, setOrders] = useState([]);
  useEffect(() => {
    getTotalOrders({
      page: 1,
      limit: 10,
    }).then((res) => {
      console.log(res);
      setOrders(res);
    });
  
  }, []);

  const dateConverter =({date})=>{
    // 2024-05-07T18:26:25.000Z to 2024-05-07 6:26:25 PM
    const dateObj = new Date(date);
    const dateStr = dateObj.toDateString();
    const timeStr = dateObj.toLocaleTimeString();
    return `${dateStr} ${timeStr}`;
    
  }
  return (
    <div className="my-3">
      <div className="sales-stat-container">
        <div style={{ display: "flex" }}>
          <div>
            <span className="sales-stat-title">Order Status</span>
          </div>
        </div>
        <div className="row text-center py-2 mb-2 Recent-Order-Table-Header mt-4">
          <div className="col-3">ORDER ID</div>

          <div className="col-3">ORDER DATE</div>

          <div className="col-3">ORDER STATUS</div>
          <div className="col-3">PAYMENT STATUS</div>
        </div>
        {Orders.map((order) => {
          return (
            <div className="row text-center py-2 my-2 Recent-Order-Table-Row">
              <div className="col-3">{order.OrderId}</div>

              <div className="col-3">
                  {dateConverter({date:order.createdAt})}
                </div>

              <div
                className="col-3"
                style={{
                  color:
                    order.OrderStatus === "Placed" || "ORDER PLACED"
                      ? "green"
                      : order.OrderStatus === "Pending"
                      ? "#FABB00"
                      : order.OrderStatus === "Shipped"
                      ? "#C48FFF80"
                      : order.OrderStatus === "Delivered"
                      ? "#32482480"
                      : "#FF0000",

                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
              >
                {order.OrderStatus == 0 ? "Cancelled" : order.OrderStatus}
              </div>
              <div className="col-3">{order.PaymentStatus}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
