import React, { useEffect, useState } from "react";
import "../../../Styles/Elements/TopSelling.css";
import { getAnalytics } from "../../../API/Analytics/Sales";

export default function TopSellingTable() {
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  useEffect(() => {
 getAnalytics().then((res) => {
   
    setTopSellingProducts(res.topSellingProducts);
  }
  ).catch((err) => {
    alert(err);
  });
  }, []);
  

 

  return (
    <div className="my-3">
      <div className="sales-stat-container">
        <div style={{ display: "flex" }}>
          <div>
            <span className="sales-stat-title">Top Selling</span>
          </div>
        </div>
        <div className="row text-center py-2 mb-2 Recent-Order-Table-Header mt-4">
          <div className="col-3">PRODUCT NAME</div>

          <div className="col-3">PRICE </div>

          <div className="col-3">SOLD</div>
          <div className="col-3">EARNING</div>
        </div>
        {topSellingProducts.map((item) => {
          return (
            <div className="row text-center py-2 my-2 Recent-Order-Table-Row fw-bold text-black" style={{
              fontSize: "16px"
            }}>
              <div className="col-3">{item.ProductDetails.Name}</div>

              <div className="col-3">{

                item.ProductDetails.Price
              }</div>

              <div
                className="col-3"
                style={{
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
              >
                { item.count}
              </div>
              <div
                className="col-3"
                style={{
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
              >
                2000
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
