import React, { useState } from "react";
import "../../../../Styles/Graphs/SalesByCategory.css";
import ReactApexChart from "react-apexcharts";

export default function SalesStat() {
  const [dataValues, setDataValues] = useState([
    {
      name: "Sales",
      data: new Array(12).fill(0).map(() => Math.floor(Math.random() * 9000)),
    },
  ]);

  const [data, setData] = useState({
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + "₹";
          },
        },
      },
    },
  });

  const Options = [
    {
      label: "Day",
    },
    {
      label: "Week",
    },
    {
      label: "Month",
    },
    {
      label: "Year",
    },
  ];
  const [selectedOption, setSelectedOption] = useState("Day");

  const handleOptionClick = (option, index) => {
    setSelectedOption(option.label);

    let categories = [];

    switch (option.label) {
      case "Day":
        categories = [
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
        ];
        break;
      case "Week":
        categories = ["Week 1", "Week 2", "Week 3", "Week 4"];
        break;
      case "Month":
        categories = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        break;
      case "Year":
        categories = ["2021", "2022", "2023"];
        break;
      default:
        break;
    }

    setData((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories,
        },
      },
    }));
  };

  return (
    <div className="my-3">
      <div className="sales-category-stat-container">
        <div style={{ display: "flex" }}>
          <div>
            <span className="sales-category-stat-title">Sales Stats</span>
          </div>
          <div className="sales-category-stat-options">
            <div className="sales-category-stat-option-container">
              {Options.map((option, index) => (
                <div
                  key={index}
                  className={`sales-stat-option ${
                    selectedOption === option.label
                      ? "sales-stat-option-selected"
                      : ""
                  }`}
                  onClick={() => handleOptionClick(option, index)}
                >
                  <span>{option.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div id="chart">
          <ReactApexChart
            options={data.options}
            series={dataValues}
            type="line"
            height={350}
          />
        </div>
      </div>
    </div>
  );
}
