import React, { useEffect } from "react";
import { GetAllProducts } from "../../../API/Products/Products";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";

import "../../../Styles/Routes/Inventory.css"; // Import the CSS file
import HeaderInput from "../../../Components/CMS/Dashboard/HeaderInput";
import { Helmet } from "react-helmet";
import { COLORS } from "../../../Utils/COLORS";
import { Route_Endpoints } from "../../../Utils/Constants";
import NoProduct from "../../../Components/CMS/Inventory/NoProduct";
import axios from "axios";
import serialize from "serialize-javascript";
import { environment } from "../../../API";

export default function CategoryProducts() {
  const location = useLocation();
  const categoryName = location.state?.categoryName;
  const navigate = useNavigate();

  const [Products, setProducts] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      try {
        const response = await axios.post(
          `${environment.baseURL}/categories/getAllCategoryProducts`,
          JSON.stringify({ CategoryName: categoryName }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching category products:", error);
      } finally {
        setLoading(false);
      }
    };

    if (categoryName) {
      fetchCategoryProducts();
    }
  }, [categoryName]);

  return (
    <>
      <Helmet>
        <title>Category Products | Dori Decor</title>
      </Helmet>
      <div
        style={{
          width: "100%",
        }}
      >
        <div className="m-2">
          <HeaderInput />
        </div>

        <div
          onClick={() => {
            navigate("/inventory/AddProduct");
          }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: COLORS.primary,
              width: "fit-content",
              padding: "10px 20px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <span>Add New Product</span>
          </div>
        </div>

        <div className="inventory-container row container-fluid">
          {Products.length > 0
            ? Products.map((product, index) => (
                <div className="col-md-6">
                  <div key={index} className="inventory-product">
                    <div>
                      <div className="inventory-product-image">
                        <img
                          src={product.ProductImage[0].img}
                          alt={product.Name}
                        />
                      </div>
                    </div>
                    <div className="inventory-product-info">
                      <div>
                        <p>
                          <span>
                            <b>{product.Name}</b>
                          </span>
                        </p>
                      </div>
                      <div>
                        <span>
                          <b>Price</b>: {product.price}
                        </span>
                      </div>
                      <div>
                        <span>
                          <b>MRP</b>: {product.mrp}
                        </span>
                      </div>
                      <div>
                        <span>
                          <b>Quantity Available</b>: {product.quantityAvailable}
                        </span>
                      </div>
                    </div>
                    <div className="inventory-product-actions">
                      <div
                        onClick={() => {
                          navigate(
                            Route_Endpoints.INVENTORY_ROUTES.EDIT_PRODUCT,
                            {
                              state: {
                                ProductId: product.ProductId,
                              },
                            }
                          );
                        }}
                        className="inventory-action-btn"
                      >
                        <span>Edit Product</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : !Loading && <NoProduct />}
        </div>
      </div>
    </>
  );
}
