import instance from "..";
import { AnalyticsEndpoint } from "../endpoints";

export const getTotalOrders = async ({ page, pageSize }) => {
  return new Promise((resolve, reject) => {
    instance
      .post(AnalyticsEndpoint.getTotalOrders, {
        page,
        pageSize,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
