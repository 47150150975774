import instance from "..";
import { StoreProfileEndpoint } from "../endpoints";

export const getStoreData = async () => {
  return new Promise((resolve, reject) => {
    instance
      .post(StoreProfileEndpoint.getStoreData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateStoreTheme = async ({ Colors }) => {
  return new Promise((resolve, reject) => {
    instance
      .post(StoreProfileEndpoint.updateStoreTheme, { Colors })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateStoreLogo = async ({ Image }) => {
  return new Promise((resolve, reject) => {
    instance
      .post(StoreProfileEndpoint.updateStoreLogo, { Image })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetBanners = async () => {
  return new Promise((resolve, reject) => {
    instance
      .post(StoreProfileEndpoint.getBanners)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const AddNewBanner = async ({ Image }) => {
  return new Promise((resolve, reject) => {
    instance
      .post(StoreProfileEndpoint.createNewBanner, { Image })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
