import React from "react";
import { Helmet } from "react-helmet";
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import PrimarySwitch from "../../Components/Elements/PrimarySwitch";
export default function Payments() {
  return (
    <>
      <Helmet>
        <title>Payments | Rebuilters</title>
      </Helmet>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <div className="my-4 mx-3">
          <HeaderInput />
        </div>
        <div className="mx-4" style={{ marginTop: "20px" }}>
          <div>
            <span className="manage-subscription-heading">Payouts</span>
          </div>
        </div>
      </div>
    </>
  );
}
