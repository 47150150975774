import React from "react";
import "../../../Styles/Elements/InfoBox.css";
export default function InfoBox({ GradientColors, Heading, DataText }) {
  return (
    <div
      className="mx-1 my-2"
      style={{
        background: `linear-gradient(140deg,  ${GradientColors.join(", ")})`,
        width: "100%",
        height: "200px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          padding: "10px 20px",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            {Heading}
          </span>
        </div>
        <div className="mt-3">
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            {DataText}
          </span>
        </div>
      </div>
    </div>
  );
}
