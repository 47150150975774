import axios from "axios";

// create axios instance
const AuthToken = localStorage.getItem('AuthToken')
console.log("INSTANCE CREATED")
console.log(AuthToken)
console.log("INSTANCE CREATED")
const AuthHeader = {
    AuthToken

};

const testEnv = {
    baseURL: "http://localhost:4000",
}

const prodEnv = {
    baseURL: "https://api.doridecor.in",
}

export const environment = prodEnv

const instance = axios.create({
    baseURL: environment.baseURL,
    headers: AuthHeader,
});

export default instance;