import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { COLORS } from "../../../src/Utils/COLORS";
import { SketchPicker } from "react-color";
import "../../Styles/Routes/Store.css";
import { storage } from "../../Utils/firebase";
import {
  GetBanners,
  getStoreData,
  updateStoreLogo,
  updateStoreTheme,
} from "../../API/Store/Store";
import { toast } from "react-toastify";
import { CloudUploadOutlined } from "@mui/icons-material";

export default function Store() {
  const [storeName, setStoreName] = React.useState();
  const [storeTheme, setStoreTheme] = React.useState("#fff");
  const [storeLogo, setStoreLogo] = React.useState("");
  const [Banners, setBanners] = React.useState([{}]);

  const fetchStoreData = async () => {
    getStoreData().then((res) => {
      const StoreProfile = res.store;
      const StoreTheme = res.theme;
      setStoreName(StoreProfile.Name);
      setStoreLogo(StoreProfile.Image);
      setStoreTheme(JSON.parse(StoreTheme.Colors).primary);
    });
    GetBanners().then((Banners) => {
      setBanners(Banners);
    });
  };

  const changeStoreLogo = () => {
    // Open file picker and upload the selected file
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleLogoChange);
    fileInput.click();
  };

  const handleLogoChange = (event) => {
    const BrandId = localStorage.getItem("BrandId");
    const file = event.target.files[0];

    // Upload the selected file to Firebase storage
    const uploadTask = storage.ref(`Store-Logo/${BrandId}.png`).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Handle progress updates if needed
      },
      (error) => {
        console.log(error);
      },
      () => {
        // The upload is complete
        console.log("Logo uploaded successfully!");
        storage
          .ref(`Store-Logo/${BrandId}.png`)
          .getDownloadURL()
          .then((url) => {
            setStoreLogo(url);
            updateStoreLogo({
              Image: url,
            });
            toast("Logo updated successfully!", {
              autoClose: 2000,
            });
          });
      }
    );
  };

  const updateStoreThemeColor = () => {
    updateStoreTheme({
      Colors: {
        primary: storeTheme.hex,
      },
    })
      .then((res) => {
        toast("Theme updated successfully!", {
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update theme!");
      });
  };
  const onDrag = (color) => {
    setStoreTheme(color);
  };

  useEffect(() => {
    fetchStoreData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Store | Rebuilters</title>
      </Helmet>

      <div className="store-container">
        <div className="store-info">
          <div className="store-info-heading">
            <span className="fw-bold">Store Info</span>
          </div>
          <div className="store-info-content">
            <div className="store-logo-container">
              <img
                alt="Store-Large-Logo"
                src={storeLogo}
                className="store-logo"
              />
            </div>
            <div className="store-details">
              <div className="store-name">
                <span className="fw-bold">{storeName}</span>
                <br />
                <div className="store-url">
                  <span className="store-link">
                    https://hoodiezhub.rebuilters.store
                  </span>
                </div>
                <div className="store-logo-update">
                  <div
                    className="store-update-button"
                    style={{
                      backgroundColor: COLORS.primary,
                    }}
                    onClick={changeStoreLogo}
                  >
                    <span>Update Logo</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="store-info">
          <div className="store-info-heading">
            <span className="fw-bold">Store Banners</span>
          </div>
          <div
            className="my-2"
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            <div className="row">
              <div className="col-md-4">
                <div
                  className="bg-light"
                  style={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <CloudUploadOutlined
                      style={{
                        fontSize: "50px",
                      }}
                    />
                  </div>
                  <div>
                    <span className="fw-bold">Add New Banner</span>
                  </div>
                </div>
              </div>
              <div className="col-md-8 text-center">
                {Banners.length > 0 ? (
                  <></>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",

                      justifyContent: "center",
                      height: "200px",
                    }}
                  >
                    <span className="fw-bold">
                      Banners boost visibility, engage customers, and promote
                      deals; essential for e-commerce success. Add one now!
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="store-theme">
              <div className="store-info-heading">
                <span className="fw-bold">Store Theme</span>
              </div>
              <div className="my-3">
                <SketchPicker
                  onChange={onDrag}
                  color={storeTheme}
                  onChangeComplete={onDrag}
                />
                <div
                  className="mt-3 fw-bold"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Store Theme is the default color of your store. You can change
                  it by dragging the color picker.
                </div>
                <div
                  className="store-update-button"
                  style={{
                    backgroundColor: COLORS.primary,
                  }}
                  onClick={updateStoreThemeColor}
                >
                  <span>Update Store Theme</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="store-preview">
              <div className="store-preview-heading">
                <span className="fw-bold">Store Preview</span>
              </div>
              <div className="store-preview-content">
                <div className="store-preview-frame">
                  <iframe
                    src="https://stylishstore-test.vercel.app"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="store-info-heading">
            <span className="fw-bold">Store Templates</span>
          </div>
        </div>
      </div>
    </>
  );
}
