import React from "react";

import {
  SearchOutlined,
  SettingsOutlined,
  Notifications,
} from "@mui/icons-material";
const SearchInput = () => {
  const inputStyles = {
    flex: 1,
    border: "none",
    outline: "none",
    padding: "4px 8px",
  };

  const containerStyles = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "4px",
  };

  const iconStyles = {
    marginLeft: "4px",
    color: "#999",
    cursor: "pointer",
    fontSize: "20px",
  };

  return (
    <div style={containerStyles}>
      {/* <span>
        <SearchOutlined style={iconStyles} />
      </span>
      <input type="text" placeholder="Search" style={inputStyles} />
      <span>
        <SettingsOutlined style={iconStyles} />
        <Notifications />
      </span> */}
    </div>
  );
};

export default SearchInput;
