import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import WebFont from "webfontloader";

// Check if the browser is Safari
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const clientConfig = {
  client_id:
    "923864551963-2qma2h8p2vth1k2ohojhg8q4587g57n5.apps.googleusercontent.com",
  cookie_policy: "single_host_origin",
  scope: "https://www.googleapis.com/auth/<POLICY>",
  // etc...
};

// Load the font family if the browser is not Safari
if (!isSafari) {
  WebFont.load({
    google: {
      families: ["Red Hat Display", "sans-serif"],
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <div
    style={{
      fontFamily: isSafari ? "sans-serif" : "Red Hat Display",
    }}
  >
    <GoogleOAuthProvider clientId="923864551963-2qma2h8p2vth1k2ohojhg8q4587g57n5.apps.googleusercontent.com">
   
        <App />
      
    </GoogleOAuthProvider>
  </div>
);

reportWebVitals();
