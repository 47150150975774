import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Helmet } from "react-helmet";
import "../../Styles/Routes/AddProduct.css";
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import { AddNewProduct } from "../../API/Products/Products";
import { storage } from "../../Utils/firebase";
import { toast } from "react-hot-toast";
import { CloudUploadOutlined } from "@material-ui/icons";
import UnstyledInputBasic from "../../Components/Elements/UnstyledInput";
import { COLORS } from "../../Utils/COLORS";
import axios from "axios";
import { environment } from "../../API";

export default function HeroSection() {
  const [ProductImages, setProductImages] = useState([]);
  const [ProductDescription, setProductDescription] = useState("");
  const [ProductName, setProductName] = useState("");
  const [ProductCategory, setProductCategory] = useState("");
  const [SelectedWarehouse, setSelectedWarehouse] = useState();
  const [OrderProcessingTime, setOrderProcessingTime] = useState();
  const [sectionOneLoading, setSectionOneLoading] = useState(false);
  const [sectionTwoLoading, setSectionTwoLoading] = useState(false);
  const [sectionThreeLoading, setSectionThreeLoading] = useState(false);
  const [sectionFourLoading, setSectionFourLoading] = useState(false);
  const [Variants, setVariants] = useState([]);
  const [SectionOneImages, setSectionOneImages] = useState([]);
  const [SectionTwoImages, setSectionTwoImages] = useState([]);
  const [SectionThreeImages, setSectionThreeImages] = useState([]);
  const [SectionFourImages, setSectionFourImages] = useState([]);
  const [updatedBannerSection, setUpdatedBannerSection] = useState(null);
  const [updatedFirstSection, setUpdatedFirstSection] = useState(null);
  const [updatedSecondSection, setUpdatedSecondSection] = useState(null);
  const [updatedThirdSection, setUpdatedThirdSection] = useState(null);
  const [updatedFourthSection, setUpdatedFourthSection] = useState(null);
  const [sectionOneHeading, setSectionOneHeading] = useState();
  const [sectionOneDetails, setSectionOneDetails] = useState();
  const [sectionOneButtonLink, setSectionOneButtonLink] = useState();
  const [sectionTwoHeading, setSectionTwoHeading] = useState();
  const [sectionTwoDetails, setSectionTwoDetails] = useState();
  const [sectionTwoButtonLink, setSectionTwoButtonLink] = useState();
  const [sectionThreeHeading, setSectionThreeHeading] = useState();
  const [sectionThreeDetails, setSectionThreeDetails] = useState();
  const [sectionThreeButtonLink, setSectionThreeButtonLink] = useState();
  const [sectionFourHeading, setSectionFourHeading] = useState();
  const [sectionFourDetails, setSectionFourDetails] = useState();
  const [sectionFourButtonLink, setSectionFourButtonLink] = useState();
  const [sectionOneExistingImages, setSectionOneExistingImages] = useState([]);
  const [sectionTwoExistingImages, setSectionTwoExistingImages] = useState([]);
  const [sectionThreeExistingImages, setSectionThreeExistingImages] = useState(
    []
  );
  const [sectionFourExistingImages, setSectionFourExistingImages] = useState(
    []
  );
  var sectionOne = [];
  var sectionTwo = [];
  var sectionThree = [];
  var sectionFour = [];

  const handleSectionOneImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addSectionOneImage(image));
  };

  const handleSectionTwoImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addSectionTwoImage(image));
  };

  const handleSectionThreeImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addSectionThreeImage(image));
  };

  const handleSectionFourImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addSectionFourImage(image));
  };

  const uploadSectionOneImageToCloud = async () => {
    try {
      sectionOne = []; // Assuming this is declared somewhere in your code

      for (const image of SectionOneImages) {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);

        // eslint-disable-next-line no-loop-func
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null, // Progress function is not needed in this case
            (error) => {
              // Error function ...
              console.log(error);
              reject(error); // Reject the promise if an error occurs during upload
            },
            () => {
              // complete function ...
              storage
                .ref("images")
                .child(image.name)
                .getDownloadURL()
                .then((url) => {
                  sectionOne.push({ id: Math.random(), img: url });

                  resolve(); // Resolve the promise once the URL is obtained
                })
                .catch((error) => {
                  console.log(error);
                  reject(error); // Reject the promise if an error occurs while getting the download URL
                });
            }
          );
        });
      }

      return { done: true, err: null }; // All images were uploaded successfully
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const uploadSectionTwoImageToCloud = async () => {
    try {
      sectionTwo = []; // Assuming this is declared somewhere in your code

      for (const image of SectionTwoImages) {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);

        // eslint-disable-next-line no-loop-func
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null, // Progress function is not needed in this case
            (error) => {
              // Error function ...
              console.log(error);
              reject(error); // Reject the promise if an error occurs during upload
            },
            () => {
              // complete function ...
              storage
                .ref("images")
                .child(image.name)
                .getDownloadURL()
                .then((url) => {
                  sectionTwo.push({ id: Math.random(), img: url });

                  resolve(); // Resolve the promise once the URL is obtained
                })
                .catch((error) => {
                  console.log(error);
                  reject(error); // Reject the promise if an error occurs while getting the download URL
                });
            }
          );
        });
      }

      return { done: true, err: null }; // All images were uploaded successfully
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const uploadSectionThreeImageToCloud = async () => {
    try {
      sectionThree = []; // Assuming this is declared somewhere in your code

      for (const image of SectionThreeImages) {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);

        // eslint-disable-next-line no-loop-func
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null, // Progress function is not needed in this case
            (error) => {
              // Error function ...
              console.log(error);
              reject(error); // Reject the promise if an error occurs during upload
            },
            () => {
              // complete function ...
              storage
                .ref("images")
                .child(image.name)
                .getDownloadURL()
                .then((url) => {
                  sectionThree.push({ id: Math.random(), img: url });

                  resolve(); // Resolve the promise once the URL is obtained
                })
                .catch((error) => {
                  console.log(error);
                  reject(error); // Reject the promise if an error occurs while getting the download URL
                });
            }
          );
        });
      }

      return { done: true, err: null }; // All images were uploaded successfully
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const uploadSectionFourImageToCloud = async () => {
    try {
      sectionFour = []; // Assuming this is declared somewhere in your code

      for (const image of SectionFourImages) {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);

        // eslint-disable-next-line no-loop-func
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null, // Progress function is not needed in this case
            (error) => {
              // Error function ...
              console.log(error);
              reject(error); // Reject the promise if an error occurs during upload
            },
            () => {
              // complete function ...
              storage
                .ref("images")
                .child(image.name)
                .getDownloadURL()
                .then((url) => {
                  sectionFour.push({ id: Math.random(), img: url });

                  resolve(); // Resolve the promise once the URL is obtained
                })
                .catch((error) => {
                  console.log(error);
                  reject(error); // Reject the promise if an error occurs while getting the download URL
                });
            }
          );
        });
      }

      return { done: true, err: null }; // All images were uploaded successfully
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const addSectionOneImage = (image) => {
    setSectionOneImages([...SectionOneImages, image]);
  };

  const addSectionTwoImage = (image) => {
    setSectionTwoImages([...SectionTwoImages, image]);
  };

  const addSectionThreeImage = (image) => {
    setSectionThreeImages([...SectionThreeImages, image]);
  };

  const addSectionFourImage = (image) => {
    setSectionFourImages([...SectionFourImages, image]);
  };

  const updateFirstSection = async () => {
    setSectionOneLoading(true);
    const { done, err } = await uploadSectionOneImageToCloud();
    const sectionTitle = document.getElementById("section-one-title").value;
    const sectionDescription = document.getElementById(
      "section-one-description"
    ).value;
    const sectionLink = document.getElementById("section-one-link").value;
    const authToken = localStorage.getItem("AuthToken");
    if (done) {
      console.log(sectionOne);
      sectionOneExistingImages.forEach((image) => {
        sectionOne.push(image);
      });
      try {
        const response = await axios.post(
          `${environment.baseURL}/admin/updateHeroSection`,
          {
            SectionId: "Section-f959g8q8glujj8ldb",
            SectionTitle: sectionTitle,
            SectionDescription: sectionDescription,
            SectionImages: sectionOne,
            SectionLink: sectionLink,
          },
          {
            headers: {
              Authtoken: authToken,
            },
          }
        );
        console.log(response.data);
        toast.success("Section Updated Successfully 📦");
        setSectionOneLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Something Went Wrong");
        setSectionOneLoading(false);
      }
    }
    if (!done) {
      toast.error("Error Occurred While Uploading Image");
      setSectionOneLoading(false);
    }
    if (err) {
      toast.error("Error Occurred While Uploading Image");
      setSectionOneLoading(false);
    }
  };

  const updateSecondSection = async () => {
    setSectionTwoLoading(true);
    const { done, err } = await uploadSectionTwoImageToCloud();
    const sectionTitle = document.getElementById("section-two-title").value;
    const sectionDescription = document.getElementById(
      "section-two-description"
    ).value;
    const sectionLink = document.getElementById("section-two-link").value;
    const authToken = localStorage.getItem("AuthToken");
    if (done) {
      sectionTwoExistingImages.forEach((image) => {
        sectionTwo.push(image);
      });
      try {
        const response = await axios.post(
          `${environment.baseURL}/admin/updateHeroSection`,
          {
            SectionId: "Section-f959g8q8glujj8lwl",
            SectionTitle: sectionTitle,
            SectionDescription: sectionDescription,
            SectionImages: sectionTwo,
            SectionLink: sectionLink,
          },
          {
            headers: {
              Authtoken: authToken,
            },
          }
        );
        console.log(response.data);
        toast.success("Section Updated Successfully 📦");
        setSectionTwoLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Something Went Wrong");
        setSectionTwoLoading(false);
      }
    }
    if (!done) {
      toast.error("Error Occurred While Uploading Image");
      setSectionTwoLoading(false);
    }
    if (err) {
      toast.error("Error Occurred While Uploading Image");
      setSectionTwoLoading(false);
    }
  };

  const updateThirdSection = async () => {
    setSectionThreeLoading(true);
    const { done, err } = await uploadSectionThreeImageToCloud();
    const sectionTitle = document.getElementById("section-three-title").value;
    const sectionDescription = document.getElementById(
      "section-three-description"
    ).value;
    const sectionLink = document.getElementById("section-three-link").value;
    const authToken = localStorage.getItem("AuthToken");
    if (done) {
      sectionThreeExistingImages.forEach((image) => {
        sectionThree.push(image);
      });
      try {
        const response = await axios.post(
          `${environment.baseURL}/admin/updateHeroSection`,
          {
            SectionId: "Section-f959g8q8glujj8mdu",
            SectionTitle: sectionTitle,
            SectionDescription: sectionDescription,
            SectionImages: sectionThree,
            SectionLink: sectionLink,
          },
          {
            headers: {
              Authtoken: authToken,
            },
          }
        );
        console.log(response.data);
        toast.success("Section Updated Successfully 📦");
        setSectionThreeLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Something Went Wrong");
        setSectionThreeLoading(false);
      }
    }
    if (!done) {
      toast.error("Error Occurred While Uploading Image");
      setSectionThreeLoading(false);
    }
    if (err) {
      toast.error("Error Occurred While Uploading Image");
      setSectionThreeLoading(false);
    }
  };

  const updateFourthSection = async () => {
    setSectionFourLoading(true);
    const { done, err } = await uploadSectionFourImageToCloud();
    const sectionTitle = document.getElementById("section-four-title").value;
    const sectionDescription = document.getElementById(
      "section-four-description"
    ).value;
    const sectionLink = document.getElementById("section-four-link").value;
    const authToken = localStorage.getItem("AuthToken");
    if (done) {
      sectionFourExistingImages.forEach((image) => {
        sectionFour.push(image);
      });
      try {
        const response = await axios.post(
          `${environment.baseURL}/admin/updateHeroSection`,
          {
            SectionId: "Section-f959g8q8glujj8muh",
            SectionTitle: sectionTitle,
            SectionDescription: sectionDescription,
            SectionImages: sectionFour,
            SectionLink: sectionLink,
          },
          {
            headers: {
              Authtoken: authToken,
            },
          }
        );
        console.log(response.data);
        toast.success("Section Updated Successfully 📦");
        setSectionFourLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Something Went Wrong");
        setSectionFourLoading(false);
      }
    }
    if (!done) {
      toast.error("Error Occurred While Uploading Image");
      setSectionFourLoading(false);
    }
    if (err) {
      toast.error("Error Occurred While Uploading Image");
      setSectionFourLoading(false);
    }
  };

  const fetchUpdatedSections = async () => {
    const authToken = localStorage.getItem("AuthToken");

    try {
      const response = await axios.post(
        `${environment.baseURL}/admin/getHeroSections`,
        {},
        {
          headers: {
            Authtoken: authToken,
          },
        }
      );

      const updatedSections = response.data;
      updatedSections.forEach((section) => {
        switch (section.SectionId) {
          case "Section-f959g8q8glujj8ldb":
            setUpdatedFirstSection(section);
            setSectionOneExistingImages(section.SectionImages);
            break;
          case "Section-f959g8q8glujj8lwl":
            setUpdatedSecondSection(section);
            setSectionTwoExistingImages(section.SectionImages);
            break;
          case "Section-f959g8q8glujj8mdu":
            setUpdatedThirdSection(section);
            setSectionThreeExistingImages(section.SectionImages);
            break;
          case "Section-f959g8q8glujj8muh":
            setUpdatedFourthSection(section);
            setSectionFourExistingImages(section.SectionImages);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error(error);
      toast.error("Something Went Wrong");
    }
  };

  useEffect(() => {
    fetchUpdatedSections();
  }, []);

  useEffect(() => {
    if (updatedFirstSection) {
      setSectionOneHeading(updatedFirstSection.SectionTitle || "");
      setSectionOneDetails(updatedFirstSection.SectionDescription || "");
      setSectionOneButtonLink(updatedFirstSection.SectionLink || "");
    }
    if (updatedSecondSection) {
      setSectionTwoHeading(updatedSecondSection.SectionTitle || "");
      setSectionTwoDetails(updatedSecondSection.SectionDescription || "");
      setSectionTwoButtonLink(updatedSecondSection.SectionLink || "");
    }
    if (updatedThirdSection) {
      setSectionThreeHeading(updatedThirdSection.SectionTitle || "");
      setSectionThreeDetails(updatedThirdSection.SectionDescription || "");
      setSectionThreeButtonLink(updatedThirdSection.SectionLink || "");
    }
    if (updatedFourthSection) {
      setSectionFourHeading(updatedFourthSection.SectionTitle || "");
      setSectionFourDetails(updatedFourthSection.SectionDescription || "");
      setSectionFourButtonLink(updatedFourthSection.SectionLink || "");
    }
  }, [
    updatedBannerSection,
    updatedFirstSection,
    updatedFourthSection,
    updatedSecondSection,
    updatedThirdSection,
  ]);

  return (
    <div className="add-product-container">
      <Helmet>
        <title>Hero Section | Dori Decor</title>
      </Helmet>

      <div className="container-fluid my-3">
        <div className="m-2">
          <HeaderInput />
        </div>

        <div className="row">
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleSectionOneImageSelect(e);
              }}
              id="section-one-input"
            />
            {updateFirstSection && (
              <div className="my-3 add-product-card">
                <div className="add-product-heading">First Section</div>
                <div className="my-3 add-product-image-upload">
                  <div
                    onClick={() => {
                      document.getElementById("section-one-input").click();
                    }}
                  >
                    <span className="add-product-image-upload-icon">
                      <CloudUploadOutlined
                        style={{
                          fontSize: "50px",
                        }}
                      />
                    </span>
                  </div>
                  <div className="fw-bold text-center add-product-image-upload-msg my-2">
                    <span>
                      Give your product a visual appeal - upload an image.
                      Recommended size: 900x600 pixels."
                    </span>
                  </div>
                </div>
                <div className="add-product-image-list">
                  {sectionOneExistingImages.length > 0 &&
                    sectionOneExistingImages.map((image) => (
                      <div
                        key={image.id}
                        onClick={() => {
                          //remove image
                          setSectionOneExistingImages(
                            sectionOneExistingImages.filter(
                              (img) => img.img !== image.img
                            )
                          );
                        }}
                        className="add-product-image-item"
                      >
                        <img
                          className="add-product-image"
                          src={image.img}
                          alt=""
                        />
                      </div>
                    ))}
                  {SectionOneImages.length > 0 &&
                    SectionOneImages.map((image) => (
                      <div
                        key={image.id}
                        onClick={() => {
                          setSectionOneImages(
                            SectionOneImages.filter(
                              (img) => img.id !== image.id
                            )
                          );
                        }}
                        className="add-product-image-item"
                      >
                        <img
                          className="add-product-image"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                      </div>
                    ))}
                </div>
                <div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-one-title"
                      className="add-product-info-input"
                      placeholder={"Section Title"}
                      value={sectionOneHeading}
                      onChange={(e) => setSectionOneHeading(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-one-description"
                      className="add-product-info-input"
                      placeholder={"Section Description"}
                      value={sectionOneDetails}
                      onChange={(e) => setSectionOneDetails(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-one-link"
                      className="add-product-info-input"
                      placeholder={"Section Button Link (eg. /categories) "}
                      value={sectionOneButtonLink}
                      onChange={(e) => setSectionOneButtonLink(e.target.value)}
                    />
                  </div>
                  <div
                    onClick={updateFirstSection}
                    style={{
                      backgroundColor: COLORS.primary,
                    }}
                    className="store-update-button"
                  >
                    {sectionOneLoading ? (
                      <>
                        <div>
                          <div
                            className="spinner-border text-dark"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      "Update"
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleSectionTwoImageSelect(e);
              }}
              id="section-two-input"
            />
            {updatedSecondSection && (
              <div className="my-3 add-product-card">
                <div className="add-product-heading">Second Section</div>
                <div className="my-3 add-product-image-upload">
                  <div
                    onClick={() => {
                      document.getElementById("section-two-input").click();
                    }}
                  >
                    <span className="add-product-image-upload-icon">
                      <CloudUploadOutlined
                        style={{
                          fontSize: "50px",
                        }}
                      />
                    </span>
                  </div>
                  <div className="fw-bold text-center add-product-image-upload-msg my-2">
                    <span>
                      Give your product a visual appeal - upload an image.
                      Recommended size: 900x600 pixels."
                    </span>
                  </div>
                </div>
                <div className="add-product-image-list">
                  {sectionTwoExistingImages.length > 0 &&
                    sectionTwoExistingImages.map((image) => (
                      <div
                        key={image.name}
                        onClick={() => {
                          //remove image
                          setSectionTwoExistingImages(
                            sectionTwoExistingImages.filter(
                              (img) => img.img !== image.img
                            )
                          );
                        }}
                        className="add-product-image-item"
                      >
                        <img
                          className="add-product-image"
                          src={image.img}
                          alt=""
                        />
                      </div>
                    ))}
                  {SectionTwoImages.length > 0 &&
                    SectionTwoImages.map((image) => (
                      <div
                        key={image.name}
                        onClick={() => {
                          setSectionTwoImages(
                            SectionTwoImages.filter(
                              (img) => img.name !== image.name
                            )
                          );
                        }}
                        className="add-product-image-item"
                      >
                        <img
                          className="add-product-image"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                      </div>
                    ))}
                </div>
                <div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-two-title"
                      className="add-product-info-input"
                      placeholder={"Section Title"}
                      value={sectionTwoHeading}
                      onChange={(e) => setSectionTwoHeading(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-two-description"
                      className="add-product-info-input"
                      placeholder={"Section Description"}
                      value={sectionTwoDetails}
                      onChange={(e) => setSectionTwoDetails(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-two-link"
                      className="add-product-info-input"
                      placeholder={"Section Button Link (eg. /categories) "}
                      value={sectionTwoButtonLink}
                      onChange={(e) => setSectionTwoButtonLink(e.target.value)}
                    />
                  </div>
                  <div
                    onClick={updateSecondSection}
                    style={{
                      backgroundColor: COLORS.primary,
                    }}
                    className="store-update-button"
                  >
                    {sectionTwoLoading ? (
                      <>
                        <div>
                          <div
                            className="spinner-border text-dark"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      "Update"
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleSectionThreeImageSelect(e);
              }}
              id="section-third-input"
            />
            {updateThirdSection && (
              <div className="my-3 add-product-card">
                <div className="add-product-heading">Third Section</div>
                <div className="my-3 add-product-image-upload">
                  <div
                    onClick={() => {
                      document.getElementById("section-third-input").click();
                    }}
                  >
                    <span className="add-product-image-upload-icon">
                      <CloudUploadOutlined
                        style={{
                          fontSize: "50px",
                        }}
                      />
                    </span>
                  </div>
                  <div className="fw-bold text-center add-product-image-upload-msg my-2">
                    <span>
                      Give your product a visual appeal - upload an image.
                      Recommended size: 900x600 pixels."
                    </span>
                  </div>
                </div>
                <div className="add-product-image-list">
                  {sectionThreeExistingImages.length > 0 &&
                    sectionThreeExistingImages.map((image) => (
                      <div
                        key={image.name}
                        onClick={() => {
                          //remove image
                          setSectionThreeExistingImages(
                            sectionThreeExistingImages.filter(
                              (img) => img.img !== image.img
                            )
                          );
                        }}
                        className="add-product-image-item"
                      >
                        <img
                          className="add-product-image"
                          src={image.img}
                          alt=""
                        />
                      </div>
                    ))}
                  {SectionThreeImages.length > 0 &&
                    SectionThreeImages.map((image) => (
                      <div
                        key={image.name}
                        onClick={() => {
                          setSectionThreeImages(
                            SectionThreeImages.filter(
                              (img) => img.name !== image.name
                            )
                          );
                        }}
                        className="add-product-image-item"
                      >
                        <img
                          className="add-product-image"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                      </div>
                    ))}
                </div>
                <div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-three-title"
                      className="add-product-info-input"
                      placeholder={"Section Title"}
                      value={sectionThreeHeading}
                      onChange={(e) => setSectionThreeHeading(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-three-description"
                      className="add-product-info-input"
                      placeholder={"Section Description"}
                      value={sectionThreeDetails}
                      onChange={(e) => setSectionThreeDetails(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-three-link"
                      className="add-product-info-input"
                      placeholder={"Section Button Link (eg. /categories) "}
                      value={sectionThreeButtonLink}
                      onChange={(e) =>
                        setSectionThreeButtonLink(e.target.value)
                      }
                    />
                  </div>
                  <div
                    onClick={updateThirdSection}
                    style={{
                      backgroundColor: COLORS.primary,
                    }}
                    className="store-update-button"
                  >
                    {sectionThreeLoading ? (
                      <>
                        <div>
                          <div
                            className="spinner-border text-dark"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      "Update"
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleSectionFourImageSelect(e);
              }}
              id="section-fourth-input"
            />
            {updatedFourthSection && (
              <div className="my-3 add-product-card">
                <div className="add-product-heading">Fourth Section</div>
                <div className="my-3 add-product-image-upload">
                  <div
                    onClick={() => {
                      document.getElementById("section-fourth-input").click();
                    }}
                  >
                    <span className="add-product-image-upload-icon">
                      <CloudUploadOutlined
                        style={{
                          fontSize: "50px",
                        }}
                      />
                    </span>
                  </div>
                  <div className="fw-bold text-center add-product-image-upload-msg my-2">
                    <span>
                      Give your product a visual appeal - upload an image.
                      Recommended size: 900x600 pixels."
                    </span>
                  </div>
                </div>
                <div className="add-product-image-list">
                  {sectionFourExistingImages.length > 0 &&
                    sectionFourExistingImages.map((image) => (
                      <div
                        key={image.name}
                        onClick={() => {
                          //remove image
                          setSectionFourExistingImages(
                            sectionFourExistingImages.filter(
                              (img) => img.img !== image.img
                            )
                          );
                        }}
                        className="add-product-image-item"
                      >
                        <img
                          className="add-product-image"
                          src={image.img}
                          alt=""
                        />
                      </div>
                    ))}
                  {SectionFourImages.length > 0 &&
                    SectionFourImages.map((image) => (
                      <div
                        key={image.name}
                        onClick={() => {
                          setSectionFourImages(
                            SectionFourImages.filter(
                              (img) => img.name !== image.name
                            )
                          );
                        }}
                        className="add-product-image-item"
                      >
                        <img
                          className="add-product-image"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                      </div>
                    ))}
                </div>
                <div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-four-title"
                      className="add-product-info-input"
                      placeholder={"Section Title"}
                      value={sectionFourHeading}
                      onChange={(e) => setSectionFourHeading(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-four-description"
                      className="add-product-info-input"
                      placeholder={"Section Description"}
                      value={sectionFourDetails}
                      onChange={(e) => setSectionFourDetails(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      id="section-four-link"
                      className="add-product-info-input"
                      placeholder={"Section Button Link (eg. /categories) "}
                      value={sectionFourButtonLink}
                      onChange={(e) => setSectionFourButtonLink(e.target.value)}
                    />
                  </div>
                  <div
                    onClick={updateFourthSection}
                    style={{
                      backgroundColor: COLORS.primary,
                    }}
                    className="store-update-button"
                  >
                    {sectionFourLoading ? (
                      <>
                        <div>
                          <div
                            className="spinner-border text-dark"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      "Update"
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
