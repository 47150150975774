import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../../Styles/Routes/Orders.css";
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import { Helmet } from "react-helmet";
import { Route_Endpoints } from "../../Utils/Constants";
import NoProduct from "../../Components/CMS/Inventory/NoProduct";
import { GetAllOrders } from "../../API/Orders/Orders";

export default function Inventory() {
  const navigate = useNavigate();

  const [orders, setOrders] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  useEffect(() => {
    GetAllOrders()
      .then((res) => {
        console.log(res);
        setOrders(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "gray";
      case "cancelled":
        return "red";
      case "shipped":
        return "orange";
      case "delivered":
        return "green";
      case "confirmed":
        return "blue";
      case "dispatched":
        return "lightseagreen";
      case "out for delivery":
        return "blue";
      case "returned":
        return "red";
      case "in transit":
        return "gray";
      default:
        return "";
    }
  };

  return (
    <>
      <Helmet>
        <title>Orders | Dori Decor</title>
      </Helmet>
      <div
        style={{
          width: "100%",
        }}
      >
        <div className="m-2">
          <HeaderInput />
        </div>

        <div className="order-container column">
          {orders?.length > 0
            ? orders?.map((order, index) => (
                <div className="order-product-container">
                  <div key={index} className="order-product">
                    <div className="order-details">
                      <div className="order-product-image">
                        <img
                          src={
                            order?.OrderData?.[0]?.ProductDetails
                              ?.ProductImage[0]?.img
                          }
                          alt={order?.OrderData?.[0]?.ProductDetails?.Name}
                        />
                      </div>
                      <div className="order-product-info">
                        <div>
                          <p>
                            <span>
                              <b>
                                {order?.OrderData?.[0]?.ProductDetails?.Name}
                              </b>
                            </span>
                          </p>
                        </div>
                        <div>
                          <span>
                            <b>Order-ID</b>: {order?.OrderId}
                          </span>
                        </div>
                        <div>
                          <span>
                            <b>Price</b>: {order?.Amount}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="order-product-actions">
                      <span
                        className="order-status"
                        style={{
                          backgroundColor: getStatusColor(order?.OrderStatus),
                        }}
                      >
                        {order?.OrderStatus}
                      </span>
                      <div
                        onClick={() => {
                          navigate(Route_Endpoints.ORDER_ROUTES.ORDER_DETAIL, {
                            state: {
                              OrderId: order?.OrderId,
                            },
                          });
                        }}
                        className="order-action-btn"
                      >
                        <span>Order Details</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : !Loading && <NoProduct 
            title={"No Orders Found"}
            />}
        </div>
      </div>
    </>
  );
}
