import instance from "..";
import { AnalyticsEndpoint } from "../endpoints";

export const getSales = () => {
  return new Promise((resolve, reject) => {
    instance
      .post(AnalyticsEndpoint.getSales, {
        startDate: null,
        endDate: null,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAnalytics = () => {
  return new Promise((resolve, reject) => {
    instance
      .post(AnalyticsEndpoint.getAnalytics, {
       
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
