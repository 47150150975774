export const AuthEndpoint = {
  loginWithPhoneNumber: "/Admin/Auth",
  verifyBrand: "/Auth/verifyBrand",
  loginWithEmail: "/Auth/loginWithEmail",
  adminAuth: "/admin/adminAuth",
  verifyAdmin: "/admin/verifyAdmin",
};

export const ProductsEndpoint = {
  getAllProducts: "/admin/getAllProducts",
  addNewProduct: "/admin/AddProduct",
  updateProduct: "/admin/EditProduct",
  getProductDetails: "/admin/GetProductDetails",
  deleteProduct: "/admin/DeleteProduct",
};

export const OrdersEndpoint = {
  getAllOrders: "/admin/getAllOrders",
  updateProduct: "/admin/updateAnOrder",
  getOrderDetails: "/admin/getOrderDetails",
};

export const StoreEndpoint = {
  addWarehouse: "/Brands/addWarehouse",
  getAllWarehouse: "/Brands/getAllWarehouse",
};

export const CategoryEndpoint = {
  addCategory: "/Inventory/AddCategory",
  getAllCategory: "/Inventory/GetAllCategories",
};

export const StoreProfileEndpoint = {
  getStoreData: "/Store/getStoreData",
  updateStoreTheme: "/Store/updateStoreTheme",
  updateStoreLogo: "/Store/updateStoreLogo",
  getBanners: "/Banners/getBanners",
  createNewBanner: "/Banners/createBanner",
};

export const SubscriptionEndpoint = {
  createSubscription: "/Subscription/createSubscription",
  verifySubscription: "/Subscription/verifySubscription",
  getSubscriptionStatus: "/Subscription/getSubscriptionStatus",
  terminateSubscription: "/Subscription/terminateSubscription",
};

export const AnalyticsEndpoint = {
  getAnalytics: "/Analytics/getAnalytics",
  getTotalOrders: "/admin/getAllOrders",
  getSales: "/Analytics/getSales",
  getAnalytics: "/admin/getAnalytics",
};
