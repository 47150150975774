import React from "react";
import "./Sidebar.css";
import {
  Inventory2Outlined,
  ShoppingCartOutlined,
  SettingsOutlined,
  GraphicEq,
} from "@mui/icons-material";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import SidebarLabel from "../../Elements/SidebarLabel";
import { useNavigate } from "react-router-dom";
import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

export default function Sidebar() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const Routes = [
    {
      label: "Analytics",
      path: "/analytics",
      Icon: <GraphicEq />,
    },
    // {
    //   label: "Banners",
    //   path: "/banners",
    //   Icon: <AutoAwesomeMosaicOutlinedIcon />,
    // },
    // {
    //   label: "Hero Section",
    //   path: "/hero-section",
    //   Icon: <WebOutlinedIcon />,
    // },
    {
      label: "Products",
      path: "/inventory",
      Icon: <Inventory2Outlined />,
    },
    {
      label: "Orders",
      path: "/orders",
      Icon: <ShoppingCartOutlined />,
    },
    {
      label: "Return/Exchange",
      path: "/return-and-exchange",
      Icon: <LocalShippingOutlinedIcon />,
    },
    {
      label: "Categories",
      path: "/categories",
      Icon: <AutoAwesomeMosaicOutlinedIcon />,
    },
    {
      label: "Users",
      path: "/users",
      Icon: <PersonOutlineOutlinedIcon />,
    },
  ];

  const sidebarClassName = `sidebar ${open ? "sidebar-open" : ""}`;
  const fakeDivClassName = `fakeDiv ${open ? "fakeDiv-open" : ""}`;
  return (
    <div className={fakeDivClassName}>
      <div className={sidebarClassName}>
        <div className="logo-container">
          <div className="container">
            <span onClick={() => setOpen(!open)} className="navbar-brand">
              <img
                style={{
                  width: "74px",
                }}
                src="https://dori-decor-web.vercel.app/images/logo.png"
                className="logo-img"
                alt=""
              />
            </span>

            <div className="sidebar-content">
              {Routes.map((Option) => (
                <div className="my-2" key={Option.path}>
                  <SidebarLabel
                    Path={Option.path}
                    open={open}
                    Icon={Option.Icon}
                    label={Option.label}
                    onClick={() => {
                      navigate(Option.path);
                    }}
                  />
                </div>
              ))}

              {/* make a divider line */}
              {/* <div className="divider"></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
