import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../Styles/Routes/Inventory.css";
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import { Helmet } from "react-helmet";
import { COLORS } from "../../Utils/COLORS";
import NoProduct from "../../Components/CMS/Inventory/NoProduct";
import axios from "axios";
import { toast } from "react-toastify";
import { environment } from "../../API";

export default function Banners() {
  const navigate = useNavigate();
  const [Categories, setCategories] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [selectedBanner, setSelectedBanner] = React.useState(null);

  const handleBannerUpdate = (index) => {
    const banner = Categories.find((category) => category.Index === index);
    setSelectedBanner(banner);
    navigate("/banners/update-banner", { state: { banner } });
  };

  const handleBannerDelete = async (index) => {

const confirmToDelete = window.confirm("Are you sure you want to delete this banner?");
    if (!confirmToDelete) {
      return;
    }

    try {
      const authToken = localStorage.getItem("AuthToken");
      await axios.post(
        `${environment.baseURL}/admin/deleteBanner`,
        {
          Index: index,
        },
        {
          headers: {
            AuthToken: authToken,
          },
        }
      );
      // Optionally, you can refetch the updated list of banners after a successful delete
      const response = await axios.get(
        `${environment.baseURL}/banners/getBanners`
      );
      setCategories(response.data);
      toast.success("Banner Deleted Successfully 📂");
    } catch (error) {
      console.error("Error deleting banner:", error);
      toast.error("Error Occurred While deleting Banner");
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${environment.baseURL}/banners/getBanners`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  return (
    <>
      <Helmet>
        <title>Categories | Dori Decor</title>
      </Helmet>
      <div style={{ width: "100%" }}>
        <div className="m-2">
          <HeaderInput />
        </div>
        <div
          onClick={() => {
            navigate("/banners/add-banner");
          }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: COLORS.primary,
              width: "fit-content",
              padding: "10px 20px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <span>Add New Banner</span>
          </div>
        </div>
        <div className="inventory-container row container-fluid">
          {Categories.length > 0
            ? Categories.map((category, index) => (
                <div className="col-md-6" key={category.Index}>
                  <div className="inventory-product">
                    <div>
                      <div className="inventory-product-image">
                        <img src={category.Image} alt={""} />
                      </div>
                    </div>
                    <div className="inventory-product-info">
                      <div>
                        <p>
                          <span>
                            <b>{category.detailText}</b>
                          </span>
                        </p>
                      </div>
                      <div
                        onClick={() => handleBannerUpdate(category.Index)}
                        style={{ backgroundColor: COLORS.primary }}
                        className="inventory-action-btn fw-bold"
                      >
                        Update Banner
                      </div>
                      <div
                        onClick={() => handleBannerDelete(category.Index)}
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          marginLeft: "10px",
                        }}
                        className="inventory-action-btn fw-bold"
                      >
                        Delete Banner
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : !Loading && <NoProduct />}
        </div>
      </div>
    </>
  );
}
