import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { Helmet } from "react-helmet";
import "../../Styles/Routes/OrderDetails.css";
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import { toast } from "react-hot-toast";
import UnstyledInputBasic from "../../Components/Elements/UnstyledInput";
import { COLORS } from "../../Utils/COLORS";
import { MenuItem, Select } from "@mui/material";
import { GetOrderById } from "../../API/Orders/Orders";
import axios from "axios";
import { environment } from "../../API";

const SHIPPING_STATUS = [
  {
    status: "pending",
  },
  {
    status: "confirmed",
  },
  {
    status: "shipped",
  },
  {
    status: "delivered",
  },
  {
    status: "cancelled",
  },
  {
    status: "dispatched",
  },
  {
    status: "out for delivery",
  },
  {
    status: "returned",
  },
  {
    status: "in transit",
  },
];

const PAYMENT_STATUS = [
  {
    status: "pending",
  },
  {
    status: "paid",
  },
  {
    status: "refunded",
  },
];

export default function OrderDetails() {
  const { OrderId } = useLocation().state;

  const [ProductImages, setProductImages] = useState([]);
  const [orderResult, setOrderResult] = useState();
  const [shippingId, setShippingId] = useState("");
  const [Loading, setLoading] = useState(false);
  const [ShippingStatus, setShippingStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  const handleStatusChange = (event) => {
    setShippingStatus(event.target.value);
  };

  const handlePaymentStatusChange = (event) => {
    setPaymentStatus(event.target.value);
  };

  const addProductImage = (image) => {
    setProductImages([...ProductImages, image]);
  };

  const handleImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addProductImage(image));
  };

  useEffect(() => {
    GetOrderById({ OrderId })
      .then((OrderData) => {
        const OrderInfo = OrderData.order;
        setOrderResult(OrderInfo);
        setShippingId(OrderInfo?.shipment_id);
        setPaymentStatus(OrderInfo?.PaymentStatus);
        setShippingStatus(OrderInfo?.OrderStatus);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [OrderId]);

  const UpdateDetails = async () => {
    setLoading(true);
    try {
      const AuthToken = localStorage.getItem("AuthToken");

      await axios.post(
        `${environment.baseURL}/admin/updateAnOrder`,
        {
          OrderId: OrderId,
          OrderStatus: ShippingStatus,
          PaymentStatus: paymentStatus,
          shipment_id: shippingId,
          PaymentId: "Not Available",
        },
        {
          headers: {
            AuthToken,
          },
        }
      );

      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      const errMsg = err.response.data.message;
      toast.error(errMsg);
      setLoading(false);
    }
  };

  return (
    <div className="edit-product-container">
      <Helmet>
        <title>Order Detail | Dori Decor</title>
      </Helmet>

      <div className="container-fluid my-3">
        <div className="m-2">
          <HeaderInput />
        </div>
        <div className="row">
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleImageSelect(e);
              }}
              id="image-input"
            />
            {orderResult?.OrderData?.map((product) => (
              <div className="my-3 edit-product-card">
                <div className="edit-product-heading">Product Images</div>
                <div className="edit-product-image-list">
                  {product?.ProductDetails?.ProductImage?.length > 0 &&
                    product?.ProductDetails?.ProductImage?.map((image) => (
                      <div key={image.img} className="edit-product-image-item">
                        <img
                          className="edit-product-image"
                          src={image.img}
                          alt=""
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}

            <div></div>
            <div className="my-3 edit-product-card">
              <div className="edit-product-heading mx-1 mb-4">
                Shipping Details
              </div>
              <div className="my-3">
                <div className="my-3">
                  <UnstyledInputBasic
                    value={shippingId}
                    className="edit-product-info-input"
                    onChange={(e) => {
                      setShippingId(e.target.value);
                    }}
                    placeholder="Shipping ID"
                  />
                </div>
                <div>
                  <p>{`Name: ${orderResult?.Name}`}</p>
                  <p>{`Email: ${orderResult?.Email}`}</p>
                  <p>{`Phone Number: ${orderResult?.Phone}`}</p>
                  <p>{`Address: ${orderResult?.Address}`}</p>
                </div>
              </div>
              <div
                onClick={Loading ? null : UpdateDetails}
                className="order-button"
                style={{
                  backgroundColor: COLORS.primary,
                }}
              >
                {Loading ? (
                  <>
                    <div>
                      <div class="spinner-border text-dark " role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  "Update Shipping Details"
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <input
              type="file"
              hidden
              onChange={(e) => {
                handleImageSelect(e);
              }}
              id="image-input"
            />
            {orderResult?.OrderData?.map((product) => (
              <div className="my-3 edit-product-card">
                <div className="edit-product-heading mx-1 mb-4">
                  Product Info
                </div>
                <div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      value={product?.ProductDetails?.ProductId}
                      className="edit-product-info-input"
                      placeholder={"Product ID"}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      value={`SKU: ${product?.ProductDetails?.SKU}`}
                      className="edit-product-info-input"
                      placeholder={"SKU"}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      value={product?.ProductDetails?.Name}
                      className="edit-product-info-input"
                      placeholder={"Product Name"}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      value={product?.ProductDetails?.Category}
                      className="edit-product-info-input "
                      placeholder={"Product Category"}
                    />
                  </div>
                  <div className="my-3">
                    <UnstyledInputBasic
                      value={`Quantity: ${product?.Quantity}`}
                      className="edit-product-info-input "
                      placeholder={"Quantity"}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="my-3 edit-product-card">
              <div className="edit-product-heading mx-1 mb-4">
                Shipping Status
              </div>
              <Select
                className="shipping-status-select"
                value={ShippingStatus}
                onChange={handleStatusChange}
              >
                {SHIPPING_STATUS.map((item) => (
                  <MenuItem key={item.status} value={item.status}>
                    {item.status}
                  </MenuItem>
                ))}
              </Select>

              <div
                onClick={Loading ? null : UpdateDetails}
                className="order-button"
                style={{
                  backgroundColor: COLORS.primary,
                }}
              >
                {Loading ? (
                  <>
                    <div>
                      <div class="spinner-border text-dark " role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  "Update Status"
                )}
              </div>
            </div>
            <div className="my-3 edit-product-card">
              <div className="edit-product-heading mx-1 mb-4">
                Payment Status
              </div>
              <Select
                className="shipping-status-select"
                value={paymentStatus}
                onChange={handlePaymentStatusChange}
              >
                {PAYMENT_STATUS.map((item) => (
                  <MenuItem key={item.status} value={item.status}>
                    {item.status}
                  </MenuItem>
                ))}
              </Select>

              <div
                onClick={Loading ? null : UpdateDetails}
                className="order-button"
                style={{
                  backgroundColor: COLORS.primary,
                }}
              >
                {Loading ? (
                  <>
                    <div>
                      <div class="spinner-border text-dark " role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  "Update Status"
                )}
              </div>
            </div>
          </div>
        </div>
        {}
        <div className="order-button-layout">
          <div
            className="order-button"
            style={{
              backgroundColor: COLORS.danger,
            }}
          >
            {Loading ? (
              <>
                <div>
                  <div class="spinner-border text-dark " role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              "Cancel Order"
            )}
          </div>
          <div
            className="order-button"
            style={{
              backgroundColor: COLORS.primary,
            }}
          >
            {Loading ? (
              <>
                <div>
                  <div class="spinner-border text-dark " role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              "Accept Order"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
